<template>
  <div
    class="d-flex align-items-center"
    style="gap: 8px"
  >
    <div
      v-for="({icon, title, iconSize, stateId, isShow= true}, index) in quoteStatusBar"
      :key="index"
      class="d-flex align-items-center"
      style="gap: 8px"
    >
      <div
        v-if="isShow"
        class="d-flex align-items-center"
        style="gap: 8px"
      >
        <div
          class="d-flex align-items-center"
          style="gap: 8px"
        >
          <feather-icon
            :icon="icon"
            :size="iconSize"
            :class="checkItemStateAndStatus(item, typeof stateId === 'undefined' ? index : stateId, orderStatesAndStatuses)"
          />
          <h3 :class="`text-secondary mb-0 font-weight-bolder ${checkItemStateAndStatus(item, typeof stateId === 'undefined' ? index : stateId, orderStatesAndStatuses)}`">
            {{ $t(title) }}
          </h3>
        </div>
        <feather-icon
          v-if="index !== quoteStatusBar.length - 1"
          icon="LPolygonIconFill"
          size="18"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mainConfig from '@/views/main/orders/config'

export default {
  name: 'QuoteStatusBar',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    quoteStatusBar: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { orderStatesAndStatuses, checkItemStateAndStatus } = mainConfig()

    return {
      orderStatesAndStatuses,
      checkItemStateAndStatus,
    }
  },
}
</script>
