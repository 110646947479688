import mainStore from '@/store'
import serviceModule from '@/store/main/orders'

export default function config() {
  // Constants
  const MODULE_NAME = 'orders'

  const store = mainStore
  const model = serviceModule

  // Filter Tabs
  const filterTabs = [
    {
      title: 'Rental/Sales',
      query: 'rental-sales',
      tabNumber: 2,
      pageTitle: 'Rental Order List',
    },
    {
      title: 'Purchase/Cr',
      query: 'purchase-cr',
      tabNumber: 3,
      pageTitle: 'Purchase Order/Credit Notes List',
    },
    {
      title: 'Transfer',
      query: 'transfer',
      tabNumber: 4,
      pageTitle: 'Transfer Order List',
    },
    {
      title: 'Service',
      query: 'service',
      tabNumber: 5,
      pageTitle: 'Service Order List',
    },
    {
      title: 'Sub-rent',
      query: 'sub-rent',
      tabNumber: 6,
      pageTitle: 'Sub-rental Order List',
    },
  ]

  const statusFilterTab = [
    {
      icon: 'LQuoteIconTransparent',
      query: 'quote',
      title: 'Quoted',
    },
    {
      icon: 'LSignatureIcon',
      query: 'in-box',
      title: 'Approved',
    },
    {
      icon: 'LTruckIcon',
      query: 'on-truck',
      title: 'Dispatched',
    },
    {
      icon: 'LWarehouseIcon',
      query: 'in-warehouse',
      title: 'Received',
    },
    {
      icon: 'LCreditCard',
      query: 'credit-card',
      title: 'Paid',
    },
    {
      icon: 'LDangerIcon',
      query: 'danger',
      title: 'In Conflict',
    },
  ]

  // rental and sale table filter icons
  const rentalAndSaleStatusFilterTab = [
    {
      icon: 'LQuoteIconFill',
      query: 'quote',
    },
    {
      icon: 'LSignatureIconFill',
      query: 'signature',
    },
    {
      icon: 'LTransferCalendarIcon',
      query: 'transfer',
    },
    {
      icon: 'LTruckIcon',
      query: 'on-truck',
    },
    {
      icon: 'LWarehouseIcon',
      query: 'in-warehouse',
    },
    {
      icon: 'LCreditCardIconFill',
      query: 'credit-card',
    },
  ]

  const orderStatus = [
    {
      icon: 'LQuoteIconFill',
    },
    {
      icon: 'LSignatureIconFill',
    },
    {
      icon: 'LCreditCardIconFill',
    },
    {
      icon: 'LTruckIcon',
    },
    {
      icon: 'LWarehouseIcon',
    },
    {
      icon: 'LBoxSentIcon',
    },
  ]

  const ORDERS_STATUS_NO_ACTION = Object.freeze({
    id: 'no_action',
    status: 0,
    title: 'To be done',
    style: {
      color: '#D9D9D9',
      styleName: 'ORDERS_STATUS_NO_ACTION',
    },
  })
  const ORDERS_STATUS_IN_PROGRESS = Object.freeze({
    id: 'in_progress',
    status: 1,
    title: 'In progress',
    style: {
      color: '#4E1476',
      styleName: 'ORDERS_STATUS_IN_PROGRESS',
    },
  })
  const ORDERS_STATUS_ATTENTION = Object.freeze({
    id: 'attention',
    status: 2,
    title: 'Attention!',
    style: {
      color: '#BB2124',
      styleName: 'ORDERS_STATUS_ATTENTION',
    },
  })
  const ORDERS_STATUS_WARNING = Object.freeze({
    id: 'warning',
    status: 3,
    title: 'Warning',
    style: {
      color: '#F0AD4E',
      styleName: 'ORDERS_STATUS_WARNING',
    },
  })
  const ORDERS_STATUS_READY = Object.freeze({
    id: 'ready',
    status: 4,
    title: 'Ready',
    style: {
      color: '#00B139',
      styleName: 'ORDERS_STATUS_READY',
    },
  })
  const ORDERS_STATUS_VOID = Object.freeze({
    id: 'void',
    status: 5,
    title: 'Void',
    style: {
      color: '#000000',
      styleName: 'ORDERS_STATUS_VOID',
    },
  })

  const ORDERS_STATUS_NOT_READY = Object.freeze({
    id: 'not_ready',
    status: 6,
    title: 'Not Ready',
    style: {
      color: '#000000',
      styleName: 'ORDERS_STATUS_NOT_READY',
    },
  })

  const ORDERS_STATUS_MISSING = Object.freeze({
    id: 'missing',
    status: 7,
    title: 'Missing',
    style: {
      color: '#000000',
      styleName: 'ORDERS_STATUS_MISSING',
    },
  })

  const ORDERS_STATUS_LATE = Object.freeze({
    id: 'Late',
    status: 8,
    title: 'Late',
    style: {
      color: 'red',
      styleName: 'ORDERS_STATUS_LATE',
    },
  })
  const ORDERS_STATUS_ASSETS_NOT_PRESENTED = Object.freeze({
    id: 'assets_not_presented',
    status: 9,
    title: 'Assets not presented',
    style: {
      color: '#000000',
      styleName: 'ORDERS_STATUS_ASSETS_NOT_PRESENTED',
    },
  })
  const ORDERS_STATUS_INCOMING_ASSETS = Object.freeze({
    id: 'incoming_assets',
    status: 10,
    title: 'Incoming assets',
    style: {
      color: '#000000',
      styleName: 'ORDERS_STATUS_INCOMING_ASSETS',
    },
  })
  const ORDERS_STATUS_RECEIVE_WARNING_ASSETS = Object.freeze({
    id: 'receive_warning_assets',
    status: 11,
    title: 'Receive Warning',
    style: {
      color: '#F0AD4E',
      styleName: 'ORDERS_STATUS_RECEIVE_WARNING_ASSETS',
    },
  })
  const ORDERS_STATUS_DAMAGED_ASSETS = Object.freeze({
    id: 'damaged_assets',
    status: 12,
    title: 'Damaged Assets',
    style: {
      color: '#BB2124',
      styleName: 'ORDERS_STATUS_DAMAGED_ASSETS',
    },
  })
  const ORDERS_STATUS_PENDING_ASSETS = Object.freeze({
    id: 'pending_assets',
    status: 13,
    title: 'Pending',
    style: {
      color: '#BB2124',
      styleName: 'ORDERS_STATUS_PENDING_ASSETS',
    },
  })
  const orderStatesAndStatuses = [
    { ...ORDERS_STATUS_NO_ACTION },
    { ...ORDERS_STATUS_IN_PROGRESS },
    { ...ORDERS_STATUS_ATTENTION },
    { ...ORDERS_STATUS_WARNING },
    { ...ORDERS_STATUS_READY },
    { ...ORDERS_STATUS_VOID },
    { ...ORDERS_STATUS_NOT_READY },
    { ...ORDERS_STATUS_MISSING },
    { ...ORDERS_STATUS_LATE },
    { ...ORDERS_STATUS_ASSETS_NOT_PRESENTED },
    { ...ORDERS_STATUS_INCOMING_ASSETS },
    ORDERS_STATUS_RECEIVE_WARNING_ASSETS,
    ORDERS_STATUS_DAMAGED_ASSETS,
    ORDERS_STATUS_PENDING_ASSETS,
  ]

  function checkItemStateAndStatus(item, index, stateAndStatuses) {
    if (!item) {
      return 'ORDERS_STATUS_NO_ACTION'
    }
    // eslint-disable-next-line no-nested-ternary,no-undef
    return item.state === index
      ? `${stateAndStatuses.find(({ status }) => status === item.status).style.styleName}`
      : index < item.state ? 'ORDERS_STATUS_READY' : 'ORDERS_STATUS_NO_ACTION'
  }

  return {
    store,
    model,
    filterTabs,
    MODULE_NAME,
    orderStatus,
    statusFilterTab,
    ORDERS_STATUS_VOID,
    ORDERS_STATUS_READY,
    ORDERS_STATUS_WARNING,
    orderStatesAndStatuses,
    checkItemStateAndStatus,
    ORDERS_STATUS_ATTENTION,
    ORDERS_STATUS_NO_ACTION,
    ORDERS_STATUS_IN_PROGRESS,
    rentalAndSaleStatusFilterTab,
  }
}
